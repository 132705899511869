import React, { useEffect } from 'react';

import Divider from '@components/Divider';
import { OneColumn } from '@components/Layouts/OneColumn';
import Loading from '@components/Loading';
import { ReportAccordion } from '@presenters/web/components/ReportAccordion/ReportAccordion';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
import { useFetchMembershipFoundationReports } from '@hooks/useFetchMembershipFoundationReports';

import { SitecoreReportTypeEnum } from '@typings/sitecore';

const MembershipFoundationReport: React.FC = () => {
  const { t } = useTranslation();

  const { reports, isLoading } = useFetchMembershipFoundationReports();

  useEffect(() => {
    window.localStorage.removeItem('activeTabValue');
    window.localStorage.removeItem('activeDistrictTabValue');
  }, []);

  if (isLoading) return <Loading />;

  return (
    <OneColumn className="mb-20">
      <Helmet
        titleTemplate={`${t('metadata.title.reports', 'Reports')}: %s | ${t(
          'metadata.title.default',
          'My Rotary'
        )}`}
      />
      <h1
        id="membership-and-foundation-reports-title-testid"
        className="text-naturals-gray-600 font-semibold text-xl-38 mobile-m:text-xl-48 tablet:text-xl-58 leading-lg-45 mobile-m:leading-xl-57 tablet:leading-xl-70 tablet:p-0 m-0 my-8 tablet:mb-12"
      >
        {t('membership-and-foundation-reports.reports-title', 'Reports')}
      </h1>
      <div className="flex flex-col w-full">
        {(reports[SitecoreReportTypeEnum.Individual]?.reportData ?? []).length >
          0 && (
          <div className="flex flex-col">
            <ReportAccordion
              sitecoreReportsData={
                reports[SitecoreReportTypeEnum.Individual]?.reportData ?? []
              }
              title="membership-and-foundation-reports.individual-reports-title"
              titleLabel="Individual Reports"
            />
            <Divider classname="my-12 tablet:my-16 border-dblue" />
          </div>
        )}

        {(reports[SitecoreReportTypeEnum.Club]?.reportData ?? []).length >
          0 && (
          <div className="flex flex-col">
            <ReportAccordion
              sitecoreReportsData={
                reports[SitecoreReportTypeEnum.Club]?.reportData ?? []
              }
              title="membership-and-foundation-reports.club-reports-title"
              titleLabel="Club Reports"
            />

            <Divider classname="my-12 tablet:my-16 border-dblue" />
          </div>
        )}

        {(reports[SitecoreReportTypeEnum.District]?.reportData ?? []).length >
          0 && (
          <div className="flex flex-col">
            <ReportAccordion
              sitecoreReportsData={
                reports[SitecoreReportTypeEnum.District]?.reportData ?? []
              }
              title="membership-and-foundation-reports.district-reports-title"
              titleLabel="District Reports"
            />
            <Divider classname="my-12 tablet:my-16 border-dblue" />
          </div>
        )}

        {(reports[SitecoreReportTypeEnum.Region]?.reportData ?? []).length >
          0 && (
          <div className="flex flex-col">
            <ReportAccordion
              sitecoreReportsData={
                reports[SitecoreReportTypeEnum.Region]?.reportData ?? []
              }
              title="membership-and-foundation-reports.region-reports-title"
              titleLabel="Regional Reports"
            />
            <Divider classname="my-12 tablet:my-16 border-dblue" />
          </div>
        )}
        {(reports[SitecoreReportTypeEnum.Grants]?.reportData ?? []).length >
          0 && (
          <ReportAccordion
            sitecoreReportsData={
              reports[SitecoreReportTypeEnum.Grants]?.reportData ?? []
            }
            title="membership-and-foundation-reports.grants-reports-title"
            titleLabel="Grants Reports"
          />
        )}
      </div>
    </OneColumn>
  );
};

export default MembershipFoundationReport;
